<template>
    <div class="layout">
        <doen-error :error="error" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { merge } from 'lodash';

import DoenError from '~/patterns/molecules/error.vue';
import favicons from '~/mixins/favicons';

export default {
    components: {
        DoenError
    },
    mixins: [favicons],
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    },
    head() {
        return this.layoutHead;
    },
    computed: {
        ...mapGetters('sites', {
            siteTheme: 'theme'
        }),
        layoutHead() {
            return merge(
                {
                    ...this.$nuxtI18nHead(),
                    bodyAttrs: {
                        'data-theme': this.siteTheme
                    }
                },
                this.faviconHead
            );
        }
    }
};
</script>
