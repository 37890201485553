'use strict';

import Vue from 'vue';

// The initial state
export const state = () => ({
    messages: []
});

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
export const getters = {
    messages: ({ messages }) => messages
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
export const actions = {
    add({ commit }, message) {
        return commit('addMessage', message);
    },
    remove({ commit }) {
        return commit('removeOldest');
    }
};

// Mutations change the actual data in the state
export const mutations = {
    // eslint-disable-next-line no-shadow
    clearAll(state) {
        Vue.set(state.messages, []);
    },
    // eslint-disable-next-line no-shadow
    addMessage(state, message) {
        // Check duplicates
        if (!state.messages.includes(message)) {
            state.messages.push(message);
        }
    },
    // eslint-disable-next-line no-shadow
    removeOldest(state) {
        state.messages.shift();
    }
};
