import { get, isEmpty } from 'lodash';
import config from '~/config/config';

export default function(context) {
    const siteId = context.store.getters['sites/activeSite'];
    if (siteId !== 0) {
        return;
    }

    const host = getHost(context),
        locale = get(context, 'store.state.i18n.locale', config.defaults.locale),
        newSiteId = getSiteId(host, locale);

    if (newSiteId !== siteId) {
        context.store.dispatch(
            'sites/changeSite',
            newSiteId
        );
    }
}

export function getSiteId(host, locale) {
    const locales = config.sites[host];

    if (!isEmpty(locales)) {
        return locales[locale] || config.defaults.siteId;
    }

    return config.defaults.siteId;
}

export function getHost(context) {
    if (process.server) {
        return context.req.headers.host;
    }

    return window.location.host;
}
