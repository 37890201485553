<template>
    <button class="button button--primary" :class="classes">
        <span class="button__icon">
            <slot name="icon-left" />
        </span>

        <slot />

        <span class="button__icon">
            <slot name="icon-right" />
        </span>
    </button>
</template>

<script>
'use strict';

export default {
    computed: {
        classes() {
            if (this.$slots['icon-left']) {
                return 'button--icon-left';
            } else if (this.$slots['icon-right']) {
                return 'button--icon-right';
            }
            return '';
        }
    }
};

</script>
