<template>
    <div class="layout-border">
        <div class="layout-border__content">
            <div class="layout">
                <nuxt class="layout__main" />
            </div>

            <client-only>
                <dn-cookiebar />
            </client-only>
        </div>
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import { merge } from 'lodash';

import seo from '~/mixins/seo';
import DnCookiebar from '~/patterns/molecules/cookie-consent/dn-cookiebar.vue';
import auth from '~/mixins/auth.js';
import favicons from '~/mixins/favicons';

export default {
    components: {
        DnCookiebar
    },
    mixins: [seo, auth, favicons],
    head() {
        return this.layoutHead;
    },
    computed: {
        ...mapGetters('sites', {
            siteTheme: 'theme'
        }),
        layoutHead() {
            return merge(
                {
                    ...this.$nuxtI18nHead(),
                    bodyAttrs: {
                        'data-theme': this.siteTheme
                    }
                },
                this.seoHead,
                this.faviconHead
            );
        }
    }
};
</script>
