<template>
    <div :class="classes" />
</template>

<script>
'use strict';

export const defaultType = 'default',
    coloredType = 'colored';

export default {
    props: {
        type: {
            type: String,
            validator: value => [defaultType, coloredType].includes(value),
            default: defaultType
        }
    },
    data() {
        return {
            defaultType,
            coloredType
        };
    },
    computed: {
        classes() {
            if (this.type === coloredType) {
                return 'logo-colored';
            }
            return 'logo';
        }
    }
};

</script>

<style lang="less" src="./logo.less" />
