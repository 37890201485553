'use strict';

import Vue from 'vue';
import { isEmpty, get } from 'lodash';
import query from '~/graphql';
import getMainNavigation from '~/graphql/queries/getMainNavigation.graphql';
import getFooterNavigation from '~/graphql/queries/getFooterNavigation.graphql';

export const state = () => ({
    main: [],
    footer: []
});

export const getters = {
    main: (myState, myGetters, rootState, rootGetters) => myState.main[rootGetters['sites/activeSite']],
    footer: (myState, myGetters, rootState, rootGetters) => myState.footer[rootGetters['sites/activeSite']],
    otherLocale: (myState, myGetters, rootState) => rootState.i18n.locale === 'en' ? 'nl' : 'en'
};

export const actions = {
    getMain({ dispatch, getters: myGetters }) {
        const main = myGetters.main;

        if (main) {
            return main;
        }

        return dispatch('fetchMain');
    },
    getFooter({ dispatch, getters: myGetters }) {
        const footer = myGetters.footer;

        if (footer) {
            return footer;
        }

        return dispatch('fetchFooter');
    },
    async fetchMain({ commit, rootGetters }) {
        const result = await query.call(this, getMainNavigation, { siteId: rootGetters['sites/activeSite'] }),
            main = get(result, 'data.globalSet.hoofdnavigatie', []);

        if (!isEmpty(main)) {
            commit('setMain', {
                siteId: rootGetters['sites/activeSite'],
                main
            });
        }

        return main;
    },
    async fetchFooter({ commit, rootGetters }) {
        const result = await query.call(this, getFooterNavigation, { siteId: rootGetters['sites/activeSite'] });

        return commit('setFooter', {
            siteId: rootGetters['sites/activeSite'],
            footer: {
                left: {
                    header: get(result, 'data.globalSet.footerHeaderLeft', ''),
                    links: get(result, 'data.globalSet.footerLeft.0.linkBlock', [])
                },
                middle: {
                    header: get(result, 'data.globalSet.footerHeaderMiddle', ''),
                    links: get(result, 'data.globalSet.footerMiddle.0.linkBlock', [])
                },
                right: {
                    header: get(result, 'data.globalSet.footerHeaderRight', ''),
                    links: get(result, 'data.globalSet.footerRight.0.linkBlock', [])
                },
                bottom: {
                    links: get(result, 'data.globalSet.footerBottom.0.linkBlock', [])
                }
            }
        });
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setMain(myState, { siteId, main }) {
        Vue.set(myState.main, siteId, main);
    },
    setFooter(myState, { siteId, footer }) {
        Vue.set(myState.footer, siteId, footer);
    },
    clearCache(myState) {
        myState.main = [];
        myState.footer = [];
    }
};
