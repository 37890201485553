'use strict';

import { isNull, isString, isUndefined } from 'lodash';

export function getBodyObjectFromJwt(jwt) {
    if (looksLikeJwt(jwt)) {
        const [header, data, signature] = jwt.split('.'); // eslint-disable-line no-unused-vars

        if (!isNull(data)) {
            const decoded = base64decode(data);

            if (!isNull(decoded)) {
                return JSON.parse(decoded);
            }
        }
    }

    return {};
}

export function isJwtExpired(jwt) {
    if (!isUndefined(jwt.exp) && !isNull(jwt.exp)) {
        if ((Date.now() / 1000) < jwt.exp) {
            return false;
        }
    }

    return true;
}

export function isJwtExpirySoon(jwt, soon = 3600) {
    if (!isUndefined(jwt.exp) && !isNull(jwt.exp)) {
        const diffInSeconds = Math.abs((Date.now() / 1000) - jwt.exp);

        return diffInSeconds <= soon;
    }

    return false;
}

export function getJwtExpiry(jwt) {
    const jwtObject = getBodyObjectFromJwt(jwt);

    if (!isUndefined(jwtObject.exp) && !isNull(jwtObject.exp)) {
        return new Date(jwtObject.exp * 1000);
    }

    return Date.parse('1970-01-01 00:00');
}

export function looksLikeJwt(jwt) {
    return !isNull(jwt) &&
        isString(jwt) &&
        jwt.match(/^(?:.+?)\.(?:.+?)\.(?:.+?)$/ig);
}

export function base64decode(data) {
    if (process.server) {
        return Buffer.from(data, 'base64').toString();
    } else if (process.client) {
        return atob(data);
    }

    return null;
}
