export const state = () => ({
    visible: false
});

export const getters = {
    visible: myState => myState.visible
};

export const actions = {
    setVisibility({ commit }, visibility) {
        return commit('setVisibility', visibility);
    }
};

export const mutations = {
    setVisibility(myState, visibility) {
        myState.visible = visibility;
    }
};
