<template>
    <div>
        <template v-if="type === INTERNAL">
            <nuxt-link :to="url" :target="target" :class="selectors">
                <slot />
                <span class="button__icon">
                    <icon-select v-if="icon" :icon="icon" />
                </span>
            </nuxt-link>
        </template>

        <template v-else-if="type === EXTERNAL">
            <a :href="url" :target="target" rel="noopener noreferrer" :class="selectors">
                <slot />
                <span class="button__icon">
                    <icon-select v-if="icon" :icon="icon" />
                </span>
            </a>
        </template>
    </div>
</template>

<script>
import IconSelect from '~/patterns/atoms/icon-select/icon-select.vue';

export const INTERNAL = 'internal';
export const EXTERNAL = 'external';

export default {
    components: {
        IconSelect
    },

    props: {
        type: {
            type: String,
            validator: value => [INTERNAL, EXTERNAL].includes(value),
            default: INTERNAL
        },
        url: {
            type: [String, Object],
            required: true
        },
        target: {
            type: String,
            default: '_self'
        },
        classes: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            INTERNAL,
            EXTERNAL
        };
    },

    computed: {
        selectors() {
            return this.icon ? this.classes + ' button--icon-right' : this.classes;
        }
    }
};
</script>
