'use strict';

import Vue from 'vue';
import VueCookieConsent from '@digitalnatives/cookie-consent';

Vue.use(VueCookieConsent, {
    // Configuration here
    categories: [
        { id: 'all' }
    ],

    showRequiredCategories: true,
    reloadOnSave: true,

    components: {
        cookiebar: 'cookiebar'
    }
});
