'use strict';

import { intersection, isEmpty, unionBy } from 'lodash';
import query from '~/graphql';
import getProgramCategories from '~/graphql/queries/getProgramCategories.graphql';

export const state = () => ({
    all: [],
    relatedLoaded: []
});

export const getters = {
    all: myState => myState.all,
    getByThemes(myState, myGetters, rootState, rootGetters) {
        return ids => myState.all.filter((item) => {
            const themeIds = item.themeCategory.map(v => v.id),
                intersected = intersection(ids, themeIds);

            return intersected.length > 0 && item.siteId === rootGetters['sites/activeSite'];
        });
    }
};

export const actions = {
    getByThemes({ dispatch, getters: myGetters, state: myState }, { themes }) {
        const intersected = intersection(themes, myState.relatedLoaded);

        if (intersected.length >= themes.length) {
            return myGetters.getByThemes(themes);
        }

        return dispatch('fetchByThemes', { themes });
    },
    async fetchByThemes({ commit, rootGetters }, { themes }) {
        const result = await query.call(this, getProgramCategories, { siteId: rootGetters['sites/activeSite'], themeCategory: themes }),
            entries = result.data.categories;

        if (!isEmpty(entries)) {
            commit('appendLoadedCategories', themes);
            commit('appendProgramCategories', entries);
        }

        return entries;
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getProgramCategories, { siteId: rootGetters['sites/activeSite'] }),
            entries = result.data.categories;

        if (!isEmpty(entries)) {
            commit('setProgramCategories', entries);
        }

        return entries;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setProgramCategories(myState, programCategories) {
        myState.all = programCategories;
    },
    setProgramCategory(myState, programCategory) {
        myState.all = [
            ...myState.all,
            programCategory
        ];
    },
    appendProgramCategories(myState, programCategories) {
        myState.all = unionBy(myState.all, programCategories, v => `${v.id}-${v.siteId}`);
    },
    appendLoadedCategories(myState, categories) {
        myState.relatedLoaded = unionBy(myState.relatedLoaded, categories);
    },
    clearCache(myState) {
        myState.all = [];
        myState.relatedLoaded = [];
    }
};
