'use strict';

import { isEmpty, trim, get } from 'lodash';
import query from '~/graphql';
import getEntry from '~/graphql/queries/getEntry.graphql';
import getEntries from '~/graphql/queries/getEntries.graphql';

export const HOME_URI = '__home__';
export const PROJECT_OVERVIEW_URI = 'projecten';
export const getUriFromPath = path => trim(path, '/');

export const state = () => ({
    all: []
});

export const getters = {
    all: myState => myState.all,
    getHome(myState, myGetters, rootState, rootGetters) {
        return () => myState.all.filter((item) => {
            return item.uri === HOME_URI && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getProjectOverview(myState, myGetters, rootState, rootGetters) {
        return () => myState.all.filter((item) => {
            return item.uri === PROJECT_OVERVIEW_URI && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getByUri(myState, myGetters, rootState, rootGetters) {
        return uri => myState.all.filter((item) => {
            return item.uri === uri && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getById(myState, myGetters, rootState, rootGetters) {
        return id => myState.all.filter((item) => {
            return item.id === `${id}` && item.siteId === rootGetters['sites/activeSite'];
        });
    }
};

export const actions = {
    getById({ dispatch, getters: myGetters }, { id }) {
        const page = myGetters.getById(id)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchById', { id });
    },
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const page = myGetters.getBySlug(slug)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchBySlug', { slug });
    },
    getByUri({ dispatch, getters: myGetters, state: myState }, { uri }) {
        const page = myGetters.getByUri(uri)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchByUri', { uri });
    },
    getHome({ dispatch, getters: myGetters }) {
        const uri = HOME_URI,
            page = myGetters.getByUri(uri)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchByUri', { uri });
    },
    getProjectOverview({ dispatch, getters: myGetters }) {
        const uri = PROJECT_OVERVIEW_URI,
            page = myGetters.getByUri(uri)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchByUri', { uri });
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getEntries, { siteId: rootGetters['sites/activeSite'] }),
            entries = get(result, 'data.entry');

        if (!isEmpty(entries)) {
            commit('setPages', entries);
        }

        return entries;
    },
    async fetchById({ commit, rootGetters }, { id }) {
        const result = await query.call(this, getEntry, { siteId: rootGetters['sites/activeSite'], id }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setPage', entry);
        }

        return entry;
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getEntry, { siteId: rootGetters['sites/activeSite'], slug }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setPage', entry);
        }

        return entry;
    },
    async fetchByUri({ commit, rootGetters }, { uri }) {
        const result = await query.call(this, getEntry, { siteId: rootGetters['sites/activeSite'], uri }),
            entry = get(result, 'data.entry');

        if (!isEmpty(entry)) {
            commit('setPage', entry);
        }

        return entry;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setPages(myState, pages) {
        myState.all = pages;
    },
    setPage(myState, page) {
        myState.all = [
            ...myState.all,
            page
        ];
    },
    clearCache(myState) {
        myState.all = [];
    }
};
