'use strict';

import { mapGetters, mapState } from 'vuex';
import { get, merge, template, uniqBy } from 'lodash';

import { getUriFromPath, HOME_URI } from '~/store/pages';
import config from '~/config/config';
import { getCodeFromIsoOrLanguage } from '~/lang/locales';

export default {
    computed: {
        titleTemplate() {
            return template(
                get(config, `siteConfig[${this.activeSite}].title`, '')
            );
        },
        seoEntry() {
            if (this.entry) {
                return this.entry;
            }

            return this.byUri(this.uri)[0] || {};
        },
        seoTitle() {
            if (this.seoEntry && this.seoEntry.seo && this.seoEntry.seo.title) {
                return this.titleTemplate({
                    title: this.seoEntry.title
                });
            }

            if (this.seoEntry && this.seoEntry.title) {
                return this.titleTemplate({
                    title: this.seoEntry.title
                });
            }

            return config.defaults.title;
        },
        seoDescription() {
            if (this.seoEntry && this.seoEntry.seo) {
                return this.seoEntry.seo.description || '';
            }

            return '';
        },
        seoSocial() {
            if (this.seoEntry && this.seoEntry.seo) {
                return this.seoEntry.seo.social || {};
            }

            return {};
        },
        seoFacebook() {
            if (this.seoSocial) {
                return this.seoSocial.facebook || {};
            }

            return {};
        },
        seoTwitter() {
            if (this.seoSocial) {
                return this.seoSocial.twitter || {};
            }

            return {};
        },
        seoFacebookImage() {
            if (this.seoFacebook) {
                return this.seoFacebook.image || {};
            }

            return {};
        },
        seoTwitterImage() {
            if (this.seoTwitter) {
                return this.seoTwitter.image || {};
            }

            return {};
        },
        seoMeta() {
            return [
                { hid: 'description', name: 'description', content: this.seoDescription || '' },
                // FACEBOOK
                { hid: 'og:type', property: 'og:type', content: 'website' },
                { hid: 'og:title', property: 'og:title', content: this.seoFacebook.title },
                { hid: 'og:description', property: 'og:description', content: this.seoFacebook.description || '' },
                { hid: 'og:image', property: 'og:image', content: this.seoFacebookImage.url || '' },
                // TWITTER
                { hid: 'twitter:title', property: 'twitter:title', content: this.seoTwitter.title || '' },
                { hid: 'twitter:description', property: 'twitter:description', content: this.seoTwitter.description || '' },
                { hid: 'twitter:image', property: 'twitter:image', content: this.seoTwitterImage.url || '' }
            ];
        },
        uri() {
            return getUriFromPath(this.params.pathMatch || HOME_URI);
        },
        /**
         * Generate localisation link meta information to inform about alternate versions of this page.
         * @returns {*[]|*}
         */
        localisationLinks() {
            // <link data-n-head="ssr" data-hid="i18n-alt-en" rel="alternate" href="/en/wat-we-doen" hreflang="en">
            if (this.localisations) {
                return this.localisations.map((localisation) => {
                    const code = getCodeFromIsoOrLanguage(localisation.language);

                    return {
                        hid: `i18n-alt-${code}`,
                        rel: 'alternate',
                        href: this.localePath(localisation.uri, code),
                        hreflang: code
                    };
                });
            }

            return [];
        },
        ...mapGetters('pages', {
            byUri: 'getByUri'
        }),
        ...mapState('route', ['params']),
        ...mapGetters('sites', ['activeSite']),
        ...mapGetters('l10n', ['localisations']),
        seoHead() {
            const i18nMeta = this.$nuxtI18nHead({ addSeoAttributes: true });

            // Filter out all the i18n generated lang alts, we don't need them...
            const i18nlinks = i18nMeta.link.filter(({ hid }) => !hid.startsWith('i18n-alt-'));

            return {
                ...i18nMeta,
                title: this.seoTitle,
                meta: uniqBy([
                    ...this.seoMeta,
                    ...i18nMeta.meta
                ], 'hid'),
                link: uniqBy([
                    ...this.localisationLinks,
                    ...i18nlinks
                ], 'hid')
            };
        }
    },
    head() {
        return merge(
            this.layoutHead,
            this.seoHead
        );
    }
};
