'use strict';

import { intersection, isEmpty, unionBy } from 'lodash';
import query from '~/graphql';
import getProgramPages from '~/graphql/queries/getProgramPages.graphql';

export const state = () => ({
    all: [],
    programCategoryIdsLoaded: []
});

export const getters = {
    all: myState => myState.all,
    getByProgramCategoryIds(myState, myGetters, rootState, rootGetters) {
        return ids => myState.all.filter((item) => {
            const programCategoryIds = item.programCategory.map(v => v.id),
                intersected = intersection(ids, programCategoryIds);

            return intersected.length > 0 && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug && item.siteId === rootGetters['sites/activeSite'];
        });
    }
};

export const actions = {
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const page = myGetters.getBySlug(slug)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchBySlug', { slug });
    },
    getByProgramCategoryIds({ dispatch, getters: myGetters, state: myState }, { programCategory }) {
        const intersected = intersection(programCategory, myState.programCategoryIdsLoaded);

        if (intersected.length >= programCategory.length) {
            return myGetters.getByProgramCategoryIds(programCategory);
        }

        return dispatch('fetchByProgramCategoryIds', { programCategory });
    },
    async fetchByProgramCategoryIds({ commit, rootGetters }, { programCategory }) {
        const result = await query.call(this, getProgramPages, { siteId: rootGetters['sites/activeSite'], programCategory }),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('appendLoadedCategoryIds', programCategory);
            commit('appendProgramPages', entries);
        }

        return entries;
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getProgramPages, { siteId: rootGetters['sites/activeSite'], slug }),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('appendProgramPages', entries);
        }

        return entries;
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getProgramPages, { siteId: rootGetters['sites/activeSite'] }),
            entries = result.data.categories;

        if (!isEmpty(entries)) {
            commit('setProgramPages', entries);
        }

        return entries;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setProgramPages(myState, programCategories) {
        myState.all = programCategories;
    },
    setProgramPage(myState, programCategory) {
        myState.all = [
            ...myState.all,
            programCategory
        ];
    },
    appendProgramPages(myState, programPages) {
        myState.all = unionBy(myState.all, programPages, v => `${v.id}-${v.siteId}`);
    },
    appendLoadedCategoryIds(myState, categories) {
        myState.programCategoryIdsLoaded = unionBy(myState.programCategoryIdsLoaded, categories);
    },
    clearCache(myState) {
        myState.all = [];
        myState.programCategoryIdsLoaded = [];
    }
};
