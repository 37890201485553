'use strict';

import { isEmpty } from 'lodash';
import query from '~/graphql';

import getAnnualReports from '~/graphql/queries/getAnnualReports.graphql';
import getAnnualReport from '~/graphql/queries/getAnnualReport.graphql';

export const state = () => ({
    all: []
});

export const getters = {
    all: myState => myState.all,
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getByUri(myState, myGetters, rootState, rootGetters) {
        return uri => myState.all.filter((item) => {
            return item.uri === uri && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getById(myState, myGetters, rootState, rootGetters) {
        return id => myState.all.filter((item) => {
            return item.id === `${id}` && item.siteId === rootGetters['sites/activeSite'];
        });
    }
};

export const actions = {
    getById({ dispatch, getters: myGetters }, { id }) {
        const page = myGetters.getById(id)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchById', { id });
    },
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const page = myGetters.getBySlug(slug)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchBySlug', { slug });
    },
    getByUri({ dispatch, getters: myGetters, state: myState }, { uri }) {
        const page = myGetters.getByUri(uri)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchByUri', { uri });
    },
    async fetchById({ commit, rootGetters }, { id }) {
        const result = await query.call(this, getAnnualReport, { siteId: rootGetters['sites/activeSite'], id }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setAnnualReport', entry);
        }

        return entry;
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getAnnualReport, { siteId: rootGetters['sites/activeSite'], slug: slug.toString() }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setAnnualReport', entry);
        }

        return entry;
    },
    async fetchByUri({ commit, rootGetters }, { uri }) {
        const result = await query.call(this, getAnnualReport, { siteId: rootGetters['sites/activeSite'], uri }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setAnnualReport', entry);
        }

        return entry;
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getAnnualReports, { siteId: rootGetters['sites/activeSite'] }),
            entries = result.data.entry;

        if (!isEmpty(entries)) {
            commit('setAnnualReports', entries);
        }

        return entries;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    },
    changeSite({ commit }) {
        return commit('clearSiteSpecificContent');
    }
};

export const mutations = {
    setAnnualReports(myState, annualReports) {
        myState.all = annualReports;
    },
    setAnnualReport(myState, annualReport) {
        myState.all = [
            ...myState.all,
            annualReport
        ];
    },
    clearSiteSpecificContent(myState) {
        myState.all = [];
    },
    clearCache(myState) {
        myState.all = [];
    }
};
