import { configure } from 'vee-validate';

export default function({ app }) {
    // Overwrite standard vee-validate messages with translated ones
    configure({
        defaultMessage: (field, values) => {
            values._field_ = app.i18n.t(`${field}`);
            return app.i18n.t(`validations.${values._rule_}`, values);
        }
    });
}
