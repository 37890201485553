'use strict';

import Vue from 'vue';

export const state = () => ({
    validTabs: { one: false, two: false, three: false },
    invalidTabs: { one: false, two: false, three: false }
});

export const getters = {
    validTabs: myState => myState.validTabs,
    invalidTabs: myState => myState.invalidTabs
};

export const actions = {
    validTab({ commit }, { key, validation }) {
        commit('setValidTab', { key, validation });
    },
    invalidTab({ commit }, { key, validation }) {
        commit('setInvalidTab', { key, validation });
    }
};

// mutations
export const mutations = {
    setValidTab(myState, { key, validation }) {
        Vue.set(myState.validTabs, key, validation);
    },
    setInvalidTab(myState, { key, validation }) {
        Vue.set(myState.invalidTabs, key, validation);
    }
};
