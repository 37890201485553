<template>
    <cookiebar>
        <template v-slot:default>
            <h3 class="cookiebar__title">{{ $t('cookieConsent.cookiebar.title') }}</h3>

            <div class="cookiebar__text">
                <p>
                    <i18n path="cookieConsent.cookiebar.text">
                        <template v-slot:link>
                            <nuxt-link v-if="isDoen" :to="localePath('/privacy-cookiestatement')">{{ $t('cookieConsent.cookiebar.linkLabel') }}</nuxt-link>
                            <nuxt-link v-else-if="isBglf" :to="localePath('/privacy-en-cookie-statement')">{{ $t('cookieConsent.cookiebar.linkLabel') }}</nuxt-link>
                            <a v-else href="https://doen.nl/privacy-cookiestatement">{{ $t('cookieConsent.cookiebar.linkLabel') }}</a>
                        </template>
                    </i18n>
                </p>
            </div>
        </template>

        <template v-slot:actions="{ acceptAllCookies, dismissCookiebar }">
            <dn-button
                class="cookiebar__actions button--secondary"
                @click.native="acceptAllCookies"
            >
                {{ $t('cookieConsent.cookiebar.accept_all') }}
            </dn-button>
            <dn-button
                class="cookiebar__actions button--secondary"
                @click.native="dismissCookiebar"
            >
                {{ $t('cookieConsent.cookiebar.dismiss') }}
            </dn-button>
        </template>
    </cookiebar>
</template>

<script>
import { mapGetters } from 'vuex';
import DnButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        DnButton
    },
    computed: {
        ...mapGetters('sites', {
            isDoen: 'isDoen',
            isBglf: 'isBglf',
            isParticipaties: 'isParticipaties'
        })
    }
};
</script>

<style src="./dn-cookiebar.less" lang="less"></style>
