'use strict';

import { isEmpty, get } from 'lodash';

import query from '~/graphql';
import getRedirects from '~/graphql/queries/getRedirects.graphql';

export const state = () => ({
    all: []
});

export const getters = {
    all(myState, myGetters, rootState, rootGetters) {
        return myState.all.filter((redirect) => {
            return redirect.siteId === null || redirect.siteId === rootGetters['sites/activeSite'];
        });
    }
};

export const actions = {
    getAll({ dispatch, getters: myGetters }) {
        const entry = myGetters.all;

        if (!isEmpty(entry)) {
            return entry;
        }

        return dispatch('fetchAll');
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getRedirects, { siteId: rootGetters['sites/activeSite'].toString() }),
            redirects = result?.data?.redirects ?? [];

        if (!isEmpty(redirects)) {
            commit('setRedirects', redirects);
        }

        return redirects;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setRedirects(myState, redirects) {
        myState.all = redirects;
    },
    clearCache(myState) {
        myState.all = [];
    }
};
