'use strict';

export const state = () => ({
    active: true
});

export const getters = {

};

export const actions = {
    activate({ commit }) {
        commit('setActive', true);
    },
    deactivate({ commit }) {
        commit('setActive', false);
    }
};

// mutations
export const mutations = {
    setActive(myState, active) {
        myState.active = active;
    }
};
