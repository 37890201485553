'use strict';

import { mapGetters } from 'vuex';
import { merge } from 'lodash';

export default {
    computed: {
        faviconLink() {
            return [
                { rel: 'shortcut icon', type: 'image/x-icon', href: `/favicons/${this.siteTheme}/favicon.ico` },
                { rel: 'icon', type: 'image/png', sizes: '16x16', href: `/favicons/${this.siteTheme}/favicon-16x16.png` },
                { rel: 'icon', type: 'image/png', sizes: '32x32', href: `/favicons/${this.siteTheme}/favicon-32x32.png` },

                { rel: 'apple-touch-icon', sizes: '180x180', href: `/favicons/${this.siteTheme}/apple-touch-icon.png` },
                { rel: 'mask-icon', href: `/favicons/${this.siteTheme}/safari-pinned-tab.svg`, color: this.faviconThemeColor },

                { rel: 'manifest', href: `/favicons/${this.siteTheme}/site.webmanifest`, crossorigin: 'use-credentials' },
            ];
        },
        faviconMeta() {
            return [
                { name: 'msapplication-TileColor', content: this.faviconThemeColor },
                { name: 'msapplication-config', content: `/favicons/${this.siteTheme}/browserconfig.xml` },
                { name: 'theme-color', content: this.faviconThemeColor }
            ];
        },
        faviconThemeColor() {
            switch (this.siteTheme) {
            case 'theme--doen':
                return '#007cbe';
            case 'theme--participaties':
                return '#223262';
            case 'theme--bglf':
                return '#ffffff';
            default:
                return '#007cbe';
            }
        },
        faviconHead() {
            return {
                meta: [
                    ...this.faviconMeta,
                ],
                link: [
                    ...this.faviconLink
                ]
            };
        },
        ...mapGetters('sites', {
            siteTheme: 'theme'
        })
    },
    head() {
        return merge(
            this.layoutHead,
            this.faviconHead
        );
    }
};
