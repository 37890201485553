const locales = [
    {
        code: 'en',
        iso: 'en-EN',
        file: 'en.js'
    },
    {
        code: 'nl',
        iso: 'nl-NL',
        file: 'nl.js'
    }
];

export default locales;

export function codeToIso(code) {
    const locale = locales.find(l => l.code === code);
    return locale ? locale.iso : null;
}

export function isoToCode(iso) {
    const locale = locales.find(l => l.iso === iso);
    return locale ? locale.iso : null;
}

export function getCodeFromIsoOrLanguage(isoOrLang) {
    const locale = locales.find((l) => {
        return l.iso === isoOrLang || l.code === isoOrLang;
    });
    return locale ? locale.code : null;
}
