<template>
    <svg
        class="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <slot />
    </svg>
</template>

<script>
export default {};
</script>

<style src="./icon.less" lang="less"></style>
