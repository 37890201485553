<template>
    <div class="background background--image-login">
        <div class="layout">
            <nuxt class="layout__centered" />
        </div>

        <client-only>
            <dn-cookiebar />
        </client-only>
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import { merge } from 'lodash';

import seo from '~/mixins/seo';
import favicons from '~/mixins/favicons';
import DnCookiebar from '~/patterns/molecules/cookie-consent/dn-cookiebar.vue';

export default {
    components: {
        DnCookiebar
    },
    mixins: [seo, favicons],
    middleware: ['mijnOmgevingEnabled'],
    head() {
        return this.layoutHead;
    },
    computed: {
        ...mapGetters('sites', {
            siteTheme: 'theme'
        }),
        ...mapGetters('mijn', ['jwt']),
        layoutHead() {
            return merge(
                {
                    ...this.$nuxtI18nHead(),
                    bodyAttrs: {
                        'data-theme': this.siteTheme
                    }
                },
                this.seoHead,
                this.faviconHead
            );
        }
    }
    // beforeCreate() {
    //     if (!isNull(this.jwt)) {
    //         this.$router.push(this.localePath({ name: 'mijn' }));
    //     }
    // }
};
</script>
