'use strict';
import https from 'https';

export default function({ $axios }) {
    // Ignore https errors on local enviroments.
    // @todo, remove the serverside fix until we have a valid cert on the PROD env.
    if (process.env.APP_ENV === 'development' || process.server) {
        const agent = new https.Agent({
            rejectUnauthorized: false
        });

        $axios.onRequest((config) => {
            config.httpsAgent = agent;
        });
    }

    // This is only relevant for GraphQL apis, which do not return a http error status,
    // but rather this will trigger a promise rejection on normal graphQL errors.
    $axios.onResponse((response) => {
        if (response.data && response.data.errors && response.data.errors.length) {
            console.error(response.data.errors); // eslint-disable-line no-console
            return Promise.reject(response.data.errors);
        }
    });
}
