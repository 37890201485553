'use strict';

import { get } from 'lodash';
import config from '~/config/config';
import { getHost, getSiteId } from '~/middleware/activeSite';

export default function(context) {
    const locale = get(context, 'store.state.i18n.locale', config.defaults.locale),
        host = getHost(context),
        siteId = getSiteId(host, locale),
        gtm = config.gtm[siteId];

    context.$gtm.init(gtm);
}
