'use strict';

import { getHost, getSiteId } from '~/middleware/activeSite';
import { persistLoginStatePlugin } from '~/store/mijn';

export const plugins = [
    function listenToLocaleChanges(store) {
        store.subscribe((mutation, myState) => {
            // called after every mutation.
            // The mutation comes in the format of `{ type, payload }`.

            if (mutation.type === 'i18n/setLocale') {
                const host = store.state.host,
                    activeSiteId = store.getters['sites/activeSite'];

                // This change can also trigger before nuxtServerInit, so
                // we check if the host is known, otherwise bail.
                if (!host) {
                    return;
                }

                const siteId = getSiteId(host, mutation.payload);

                // Only change the active SiteId if it's non null
                // and if the new siteId is different from the old.
                if (siteId !== 0 && activeSiteId !== siteId) {
                    store.dispatch(
                        'sites/changeSite',
                        siteId
                    );
                }
            }
        });
    },
    function hackToRemoveNewsCacheOnSiteChange(store) {
        store.subscribe((mutation) => {
            if (mutation.type === 'sites/changeSite') {
                store.dispatch('news/clearCache');
            }
        });
    },
    function hackToRemoveCasesCacheOnSiteChange(store) {
        store.subscribe((mutation) => {
            if (mutation.type === 'sites/changeSite') {
                store.dispatch('cases/clearCache');
            }
        });
    },
    function hackToRemoveProgramPagesCacheOnSiteChange(store) {
        store.subscribe((mutation) => {
            if (mutation.type === 'sites/changeSite') {
                store.dispatch('programpages/clearCache');
            }
        });
    },
    function hackToRemoveProgramCategoriesCacheOnSiteChange(store) {
        store.subscribe((mutation) => {
            if (mutation.type === 'sites/changeSite') {
                store.dispatch('programcategories/clearCache');
            }
        });
    },
    persistLoginStatePlugin
];

export const state = () => ({
    host: ''
});

export const mutations = {
    setHost(myState, host) {
        myState.host = host;
    }
};

export const actions = {
    nuxtServerInit({ commit }, context) {
        commit(
            'setHost',
            getHost(context)
        );
    }
};

export const getters = {
    host: myState => myState.host
};
