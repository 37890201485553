'use strict';

import { isNull, isUndefined, get, isEmpty } from 'lodash';
import Cookie from 'js-cookie';

import query from '~/graphql';
import login from '~/graphql/mijn/queries/login.graphql';
import refreshLogin from '~/graphql/mijn/queries/refreshLogin.graphql';
import requestPasswordReset from '~/graphql/mijn/queries/requestResetPassword.graphql';
import resetPassword from '~/graphql/mijn/queries/resetPassword.graphql';
import createProfile from '~/graphql/mijn/queries/createProfile.graphql';
import getProfile from '~/graphql/mijn/queries/profile.graphql';
import createOrganisation from '~/graphql/mijn/queries/createOrganisation.graphql';
import updateOrganisation from '~/graphql/mijn/queries/updateOrganisation.graphql';
import valueList from '~/graphql/mijn/queries/valuelist.graphql';
import country from '~/graphql/mijn/queries/country.graphql';
import createRequest from '~/graphql/mijn/queries/createRequest.graphql';
import updateRequest from '~/graphql/mijn/queries/updateRequest.graphql';
import createContact from '~/graphql/mijn/queries/createContact.graphql';
import updateContact from '~/graphql/mijn/queries/updateContact.graphql';
import createDocument from '~/graphql/mijn/queries/createDocument.graphql';
import deleteDocument from '~/graphql/mijn/queries/deleteDocument.graphql';
import verifyEmailToken from '~/graphql/mijn/queries/verifyEmailToken.graphql';
import getRequestPdf from '~/graphql/mijn/queries/getRequestPdf.graphql';
import getAccount from '~/graphql/mijn/queries/account.graphql';
import updateAccount from '~/graphql/mijn/queries/updateAccount.graphql';

import { getBodyObjectFromJwt, getJwtExpiry, looksLikeJwt } from '~/utils/jwt';

export const VALUELIST_TYPE_FOUND_DOEN = 'found_doen';
export const VALUELIST_TYPE_LEGAL_FORM = 'legal_form';
export const VALUELIST_TYPE_CONTACT_TITLE = 'contact_title';
export const VALUELIST_TYPE_PROGRAM = 'program';
export const VALUELIST_TYPE_FINANCE_WAY = 'finance_way';

export const REQUEST_STATUS_PENDING = 'Aanvraag nog niet volledig';
export const REQUEST_STATUS_SENT = 'Aanvraag ingezonden';
export const REQUEST_STATUS_NEW_UPDATES = 'Nieuwe updates';
export const REQUEST_STATUS_IN_PROGRESS = 'Aanvraag ontvangen';
export const REQUEST_PHASE_REJECTED = 'Afgewezen';
export const REQUEST_PHASE_APPROVED = 'Goedkeuring';
export const REQUEST_PHASE_FINANCING = 'Financiering';
export const REQUEST_PHASE_COMPLETED = 'Afsluiting';

export const state = () => ({
    jwt: null,
    account: null,
    profile: null,
    valuelist: {},
    countries: []
});

export const getters = {
    jwt: myState => myState.jwt,
    jwtObject: (myState) => {
        if (looksLikeJwt(myState.jwt)) {
            return getBodyObjectFromJwt(myState.jwt);
        }

        return null;
    },
    account: myState => myState.account,
    profile: myState => myState.profile,
    contact: (myState) => {
        const contactIndex = get(myState, 'profile.contact.length', 1) - 1;
        return get(myState, `profile.contact.${contactIndex}`, null);
    },
    organisation: (myState) => {
        const organisationIndex = get(myState, 'profile.organisation.length', 1) - 1;
        return get(myState, `profile.organisation.${organisationIndex}`, null);
    },
    requests: myState => get(myState, 'profile.web_request', []),
    requestByCode: (myState, myGetters) => {
        return (code) => {
            return myGetters.requests.filter((request) => {
                return request.web_request_code === code;
            });
        };
    },
    pendingRequests: (myState, myGetters) => {
        return myGetters.requests.filter((request) => {
            return !(request.project_phase === REQUEST_PHASE_COMPLETED || request.project_phase === REQUEST_PHASE_REJECTED);
        });
    },
    completedRequests: (myState, myGetters) => {
        return myGetters.requests.filter((request) => {
            return request.project_phase === REQUEST_PHASE_COMPLETED || request.project_phase === REQUEST_PHASE_REJECTED;
        });
    },
    valuelist: myState => type => get(myState.valuelist, type, []),
    countries: myState => myState.countries
};

export const actions = {
    async refreshLogin({ commit }, { jwt }) {
        const result = await query.call(this, refreshLogin, { jwt });

        if (!isNull(result)) {
            commit('login', result.data.refreshLogin);
            return result.data.refreshLogin;
        } else {
            commit('logout');
        }

        return false;
    },
    async login({ commit, rootGetters }, { email, password }) {
        const result = await query.call(this, login, { siteId: rootGetters['sites/activeSite'].toString(), email, password });

        if (!isUndefined(result)) {
            commit('login', result.data.login);
            return result.data.login;
        }

        return false;
    },
    logout({ commit }) {
        return commit('logout');
    },
    async requestPasswordReset({ commit, rootGetters }, { email }) {
        const result = await query.call(this, requestPasswordReset, { siteId: rootGetters['sites/activeSite'].toString(), email });

        if (!isUndefined(result)) {
            return result.data.requestResetPassword;
        }

        return false;
    },
    async resetPassword({ commit, rootGetters }, { email, verificationCode, password }) {
        const result = await query.call(this, resetPassword, { siteId: rootGetters['sites/activeSite'].toString(), email, verification_code: verificationCode, password });

        if (!isUndefined(result)) {
            return result.data.resetPassword;
        }

        return false;
    },
    async verifyToken({ commit, rootGetters }, { email, verificationCode }) {
        const result = await query.call(this, verifyEmailToken, { siteId: rootGetters['sites/activeSite'].toString(), email, verification_code: verificationCode });

        if (!isUndefined(result)) {
            return result.data.verify_token;
        }

        return false;
    },
    async createProfile({ commit, rootGetters }, { email, password }) {
        const result = await query.call(this, createProfile, { siteId: rootGetters['sites/activeSite'].toString(), profile: { webreg_username: email, webreg_password: password } });

        if (!isUndefined(result)) {
            return result.data.createProfile;
        }

        return false;
    },
    getProfile({ dispatch, getters: myGetters }) {
        const profile = myGetters.profile;

        if (isNull(profile)) {
            return dispatch('fetchProfile');
        }

        return profile;
    },
    getAccount({ dispatch, getters: myGetters }) {
        const account = myGetters.account;

        if (isNull(account)) {
            return dispatch('fetchAccount');
        }

        return account;
    },
    async fetchProfile({ commit, rootGetters, getters: myGetters }) {
        const result = await query.call(this, getProfile, { siteId: rootGetters['sites/activeSite'].toString(), jwt: myGetters.jwt });

        if (!isUndefined(result)) {
            commit('setProfile', result.data.profile);
            return result.data.profile;
        }

        return false;
    },
    async fetchAccount({ commit, rootGetters, getters: myGetters }) {
        const result = await query.call(this, getAccount, { siteId: rootGetters['sites/activeSite'].toString(), jwt: myGetters.jwt });

        if (!isUndefined(result)) {
            commit('setAccount', result.data.account);
            return result.data.account;
        }

        return false;
    },
    async createOrganisation({ dispatch, rootGetters, getters: myGetters }, { organisation }) {
        const result = await query.call(this, createOrganisation, { jwt: myGetters.jwt, organisation });

        if (!isUndefined(result)) {
            return dispatch('fetchProfile');
        }

        return false;
    },
    async updateOrganisation({ dispatch, rootGetters, getters: myGetters }, { organisation }) {
        const result = await query.call(this, updateOrganisation, { jwt: myGetters.jwt, organisation });

        if (!isUndefined(result)) {
            return dispatch('fetchProfile');
        }

        return false;
    },
    getValuelist({ dispatch, getters: myGetters, rootGetters, rootState }, { type }) {
        const list = myGetters.valuelist(type);

        if (isEmpty(list)) {
            return dispatch('fetchValuelist', { type });
        }

        return list;
    },
    async fetchValuelist({ commit, rootGetters, rootState, getters: myGetters }, { type }) {
        const result = await query.call(this, valueList, { jwt: myGetters.jwt, language: rootState.i18n.locale, type });

        if (!isUndefined(result)) {
            return commit('setValuelist', { type, valuelist: result.data.valuelist });
        }

        return false;
    },
    getCountries({ dispatch, getters: myGetters }) {
        const countries = myGetters.countries;

        if (isEmpty(countries)) {
            return dispatch('fetchCountries');
        }

        return countries;
    },
    async fetchCountries({ commit, rootState, getters: myGetters }) {
        const result = await query.call(this, country, { jwt: myGetters.jwt, language: rootState.i18n.locale });

        if (!isUndefined(result)) {
            return commit('setCountries', result.data.country);
        }

        return false;
    },
    async createRequest({ dispatch, rootGetters, getters: myGetters }, { request }) {
        const result = await query.call(this, createRequest, { jwt: myGetters.jwt, request });

        if (!isUndefined(result)) {
            await dispatch('fetchProfile');
        }

        return result.data.createRequest;
    },
    async updateRequest({ dispatch, rootGetters, getters: myGetters }, { request }) {
        const result = await query.call(this, updateRequest, { siteId: rootGetters['sites/activeSite'].toString(), jwt: myGetters.jwt, request });

        if (!isUndefined(result)) {
            return dispatch('fetchProfile');
        }

        return false;
    },
    async createContact({ dispatch, rootGetters, getters: myGetters }, { contact }) {
        const result = await query.call(this, createContact, { jwt: myGetters.jwt, contact });

        if (!isUndefined(result)) {
            return dispatch('fetchProfile');
        }

        return false;
    },
    async updateContact({ dispatch, rootGetters, getters: myGetters }, { contact }) {
        const result = await query.call(this, updateContact, { jwt: myGetters.jwt, contact });

        if (!isUndefined(result)) {
            return dispatch('fetchProfile');
        }

        return false;
    },
    async createDocument({ dispatch, rootGetters, getters: myGetters }, { webRequestCode, document }) {
        const result = await query.call(this, createDocument, { jwt: myGetters.jwt, web_request_code: webRequestCode, document });

        if (!isUndefined(result)) {
            return dispatch('fetchProfile');
        }

        return false;
    },
    /**
     * Warning, always save your progress first before calling this method. This will refresh the
     * request, any unsaved changes will be overridden with stuff from STB.
     *
     * @param dispatch
     * @param rootGetters
     * @param myGetters
     * @param document
     * @returns {Promise<*>}
     */
    async deleteDocument({ dispatch, rootGetters, getters: myGetters }, { document }) {
        const result = await query.call(this, deleteDocument, { jwt: myGetters.jwt, document });

        if (!isUndefined(result)) {
            return dispatch('fetchProfile');
        }

        return false;
    },
    async updateAccount({ dispatch, rootGetters, getters: myGetters }, { account }) {
        const result = await query.call(this, updateAccount, { jwt: myGetters.jwt, siteId: rootGetters['sites/activeSite'].toString(), account });

        if (!isUndefined(result) && result.data.updateAccount) {
            return dispatch('fetchAccount');
        }

        return false;
    },
    async fetchRequestPdf({ dispatch, rootGetters, getters: myGetters }, { webRequestCode }) {
        const result = await query.call(this, getRequestPdf, { jwt: myGetters.jwt, siteId: rootGetters['sites/activeSite'].toString(), web_request_code: webRequestCode });

        if (!isUndefined(result)) {
            return result.data.request_pdf;
        }

        return false;
    }
};

export const mutations = {
    login(myState, jwt) {
        myState.jwt = jwt;
    },
    logout(myState) {
        myState.jwt = null;
        myState.profile = null;
        myState.account = null;
    },
    setProfile(myState, profile) {
        myState.profile = profile;
    },
    setAccount(myState, account) {
        myState.account = account;
    },
    setValuelist(myState, { type, valuelist }) {
        myState.valuelist = {
            ...myState.valuelist,
            [type]: valuelist
        };
    },
    clearValuelists(myState) {
        myState.valuelist = {};
    },
    setCountries(myState, countries) {
        myState.countries = countries;
    },
    deleteDocument(myState, document) {
        const profile = myState.profile;

        profile.web_request = profile.web_request.map((request) => {
            request.documents = request.documents.filter(v => v.document_key === document);
            return request;
        });

        myState.profile = profile;
    },
    clearCountries(myState) {
        myState.countries = [];
    }
};

export function persistLoginStatePlugin(store) {
    if (process.client) {
        store.subscribe((mutation) => {
            if (mutation.type === 'mijn/login') {
                Cookie.set('jwt', mutation.payload, {
                    expires: getJwtExpiry(mutation.payload),
                    secure: document.location.protocol === 'https:',
                    sameSite: 'lax'
                });
            } else if (mutation.type === 'mijn/logout') {
                Cookie.remove('jwt');
            }
        });
    }
}
