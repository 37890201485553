<template>
    <div>
        <menu-navigation :apply-link="applyLink" class="navigation--minimal" />

        <div class="layout layout--overflowed">
            <nuxt />
        </div>

        <client-only>
            <dn-cookiebar />
        </client-only>
    </div>
</template>

<script>
'use strict';

import { mapGetters, mapState } from 'vuex';
import { merge } from 'lodash';

import DnCookiebar from '~/patterns/molecules/cookie-consent/dn-cookiebar.vue';
import MenuNavigation from '~/patterns/organisms/navigation/navigation';
import favicons from '~/mixins/favicons';

const BGLF_AANVRAAG_SLUG = 'aanvragen',
    DOEN_AANVRAAG_SLUG = 'aanvraag-doen',
    DOEN_EN_AANVRAAG_SLUG = 'applications';

export default {
    components: {
        DnCookiebar,
        MenuNavigation
    },
    mixins: [favicons],
    head() {
        return this.layoutHead;
    },
    computed: {
        applyLink() {
            if (this.uri === BGLF_AANVRAAG_SLUG) {
                return '/mijn/aanvragen';
            }

            if (this.uri === DOEN_AANVRAAG_SLUG) {
                return 'https://aanvraag.doen.nl';
            }

            if (this.uri === DOEN_EN_AANVRAAG_SLUG) {
                return 'https://aanvraag.doen.nl?lang=en_US';
            }

            if (this.isBglf) {
                return '/' + BGLF_AANVRAAG_SLUG;
            }

            if (this.locale === 'en') {
                return '/' + DOEN_EN_AANVRAAG_SLUG;
            }

            return '/' + DOEN_AANVRAAG_SLUG;
        },
        uri() {
            return this.$route && this.$route.params ? this.$route.params.pathMatch : '';
        },
        ...mapGetters('sites', ['isBglf']),
        ...mapState('i18n', ['locale']),
        ...mapGetters('sites', {
            siteTheme: 'theme'
        }),
        layoutHead() {
            return merge(
                {
                    ...this.$nuxtI18nHead(),
                    bodyAttrs: {
                        'data-theme': this.siteTheme
                    }
                },
                this.faviconHead
            );
        }
    }
};
</script>
