'use strict';

import Vue from 'vue';

// The initial state
export const state = () => ({
    popups: {}
});

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
export const getters = {
    popups: ({ popups }) => popups
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
export const actions = {
    add({ commit }, { id, message }) {
        return commit('add', { id, message });
    },
    dismiss({ commit }, id) {
        return commit('dismiss', id);
    }
};

// Mutations change the actual data in the state
export const mutations = {
    // eslint-disable-next-line no-shadow
    clearAll(state) {
        Vue.set(state.popups, []);
    },
    // eslint-disable-next-line no-shadow
    add(state, { id, message }) {
        Vue.set(state.popups, id, message);
    },
    // eslint-disable-next-line no-shadow
    dismiss(state, id) {
        Vue.delete(state.popups, id);
    }
};
