'use strict';

import { isEmpty, unionBy } from 'lodash';
import query from '~/graphql';

import getProject from '~/graphql/queries/getProject.graphql';
import getProjects from '~/graphql/queries/getProjects.graphql';

export const state = () => ({
    all: [],
    searchResults: []
});

export const getters = {
    all: myState => myState.all,
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getSearchResults: myState => myState.searchResults
};

export const actions = {
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const entry = myGetters.getBySlug(slug)[0];

        if (entry) {
            return entry;
        }

        return dispatch('fetchBySlug', { slug });
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getProject, { siteId: rootGetters['sites/activeSite'], slug }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('appendProjects', [entry]);
        }

        return entry;
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getProjects, { siteId: rootGetters['sites/activeSite'] }),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('setProjects', entries);
        }

        return entries;
    },
    setSearchResults({ commit }, searchResults) {
        commit('setSearchResults', searchResults);
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setProjects(myState, projects) {
        myState.all = projects;
    },
    setProject(myState, project) {
        myState.all = [
            ...myState.all,
            project
        ];
    },
    appendProjects(myState, projects) {
        myState.all = unionBy(myState.all, projects, 'id');
    },
    setSearchResults(myState, searchResults) {
        myState.searchResults = searchResults;
    },
    clearCache(myState) {
        myState.all = [];
    }
};
