'use strict';

import { isEmpty, unionBy } from 'lodash';
import query from '~/graphql';

import getPortfolio from '~/graphql/queries/getPortfolio.graphql';
import getPortfolios from '~/graphql/queries/getPortfolios.graphql';

export const state = () => ({
    all: []
});

export const getters = {
    all: myState => myState.all,
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug && item.siteId === rootGetters['sites/activeSite'];
        });
    }
};

export const actions = {
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const entry = myGetters.getBySlug(slug)[0];

        if (entry) {
            return entry;
        }

        return dispatch('fetchBySlug', { slug });
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getPortfolio, { siteId: rootGetters['sites/activeSite'], slug }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('appendPortfolios', [entry]);
        }

        return entry;
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getPortfolios, { siteId: rootGetters['sites/activeSite'] }),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('setPortfolios', entries);
        }

        return entries;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setPortfolios(myState, portfolios) {
        myState.all = portfolios;
    },
    setPortfolio(myState, portfolio) {
        myState.all = [
            ...myState.all,
            portfolio
        ];
    },
    appendPortfolios(myState, portfolios) {
        myState.all = unionBy(myState.all, portfolios, 'id');
    },
    clearCache(myState) {
        myState.all = [];
    }
};
