<template>
    <div class="navigation" :class="navigationClasses" @clickaway="closeMenu">
        <div class="navigation__bar">
            <div class="navigation__bar__background" />
            <div class="navigation__bar__content">
                <div class="navigation__bar__logo">
                    <a
                        :href="`${newSite.url}/`"
                        class="link link--image-block"
                    >
                        <navigation-logo />
                    </a>
                </div>
                <div class="navigation__bar__links">
                    <template v-if="isBglf">
                        <template v-if="applyLink.startsWith('https://aanvraag.doen.nl')">
                            <a :href="applyLink" class="navigation__bar__links__button button button--tertiary">
                                {{ $t('navigation.links.application') }}
                            </a>
                            <a :href="applyLink" class="navigation__bar__links__apply">
                                {{ $t('navigation.links.application') }}
                            </a>
                            <a :href="applyLink" class="navigation__bar__links__account"><icon><icon-user /></icon></a>
                        </template>
                    </template>

                    <a
                        :href="`${newSite.url}/zoek`"
                        class="navigation__bar__links__search"
                    >
                        <icon><icon-search /></icon>
                    </a>

                    <div class="navigation__bar__hamburger" @click="toggleMenu">
                        <hamburger />
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation__menu">
            <div class="navigation__menu__content">
                <div class="navigation__menu__main">
                    <navlink
                        v-for="(link, index) in mainNavigation"
                        :key="index"
                        type="external"
                        :target="mapTarget(link.targetBlank)"
                        :url="mapURI(link)"
                        :icon="link.icon"
                        class="navigation__menu__mainlink"
                        @click.native="closeMenu"
                    >
                        {{ link.titel }}
                    </navlink>
                </div>

                <template v-if="isBglf">
                    <nuxt-link :to="localePath(applyLink)" class="navigation__menu__button button button--tertiary" @click.native="closeMenu">
                        <template v-if="isDoen">{{ $t('navigation.links.application_doen') }}</template>
                        <template v-else>{{ $t('navigation.links.application') }}</template>
                    </nuxt-link>
                    <div class="navigation__menu__extralink">
                        <nuxt-link :to="localePath({ name: 'mijn' })" @click.native="closeMenu">
                            <span v-if="isDoen">{{ $t('navigation.links.account_doen') }}</span>
                            <span v-else>{{ $t('navigation.links.account') }}</span>
                            <icon><icon-user /></icon>
                        </nuxt-link>
                    </div>
                </template>

                <div class="navigation__menu__socials">
                    <a href="https://www.facebook.com/bankgiroloterijfonds" class="navigation__menu__socials__link" target="_blank" rel="noreferrer noopener"><icon><icon-facebook /></icon></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
'use strict';

import { mapActions, mapGetters, mapState } from 'vuex';
import { throttle } from 'lodash';
import { mixin as Clickaway } from 'vue-clickaway';
import Navlink from '~/patterns/atoms/navlink/navlink';
import Navigation from '~/mixins/navigation';
import NavigationLogo from '~/patterns/atoms/logo/logo.vue';
import Icon from '~/patterns/atoms/icon/icon.vue';
import IconUser from '~/patterns/atoms/_icons/user.vue';
import IconSearch from '~/patterns/atoms/_icons/search.vue';
import IconFacebook from '~/patterns/atoms/_icons/facebook.vue';
import Hamburger from '~/patterns/atoms/hamburger/hamburger.vue';

import config from '~/config/config';

export default {
    components: {
        NavigationLogo,
        Icon,
        IconUser,
        IconSearch,
        Hamburger,
        IconFacebook,
        Navlink
    },
    mixins: [
        Navigation,
        Clickaway
    ],
    props: {
        applyLink: {
            type: String,
            default: '/aanvraag-doen',
            required: false
        }
    },
    data() {
        return {
            scrolling: false,
            minimalScrollForFold: 50,
            menuOpen: false,
            newSite: config.newSite,
        };
    },
    async fetch() {
        await this.getMain();
    },
    computed: {
        navigationClasses() {
            let classes = '';

            if (this.menuOpen) {
                classes += 'navigation--open';
            }
            if (this.scrolling) {
                classes += ' navigation--scrolling';
            }

            return classes;
        },
        ...mapGetters('navigation', {
            mainNavigation: 'main',
            otherLocale: 'otherLocale'
        }),
        ...mapGetters('sites', ['theme', 'isDoen', 'isBglf', 'activeSite']),
        ...mapState('i18n', ['locale']),
        ...mapGetters('l10n', ['localisations'])
    },
    watch: {
        activeSite() {
            this.getMain();
        }
    },
    mounted() {
        window.addEventListener(
            'scroll',
            throttle(this.handleScroll, 100, {
                leading: true
            })
        );
    },
    destroyed() {
        window.removeEventListener(
            'scroll',
            this.handleScroll
        );
    },
    methods: {
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        closeMenu() {
            this.menuOpen = false;
        },
        handleScroll() {
            const currentScrollPosition = window.pageYOffset || window.scrollY;

            if (currentScrollPosition > this.minimalScrollForFold) {
                this.scrolling = true;
            } else {
                this.scrolling = false;
            }
        },
        ...mapActions('navigation', ['getMain'])
    }
};
</script>

<style lang="less" src="./navigation.less" />
