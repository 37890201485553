'use strict';

import config from '~/config/config';

export const state = () => ({
    activeSite: 0,
    theme: ''
});

export const mutations = {
    changeSite(myState, siteId) {
        myState.activeSite = siteId;
        myState.theme = config.themes[myState.activeSite];
    }
};

export const actions = {
    changeSite({ commit }, siteId) {
        return commit('changeSite', siteId);
    }
};

export const getters = {
    activeSite: myState => myState.activeSite,
    theme(myState) {
        return `theme--${config.themes[myState.activeSite]}`;
    },
    isDoen(myState) {
        return myState.theme === 'doen';
    },
    isBglf(myState) {
        return myState.theme === 'bglf';
    },
    isParticipaties(myState) {
        return myState.theme === 'participaties';
    }
};
