<template>
    <div>
        <menu-navigation v-if="isDoen" apply-link="https://aanvraag.doen.nl" />
        <menu-navigation v-else apply-link="/mijn/aanvragen/" />

        <div class="layout">
            <nuxt class="layout__main" />
        </div>

        <client-only>
            <dn-cookiebar />
        </client-only>

        <footer-minimal />
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import { merge } from 'lodash';

import seo from '~/mixins/seo';
import DnCookiebar from '~/patterns/molecules/cookie-consent/dn-cookiebar.vue';
import auth from '~/mixins/auth.js';
import favicons from '~/mixins/favicons';
import MenuNavigation from '~/patterns/organisms/navigation/navigation';
import FooterMinimal from '~/patterns/atoms/footer-minimal/footer-minimal.vue';

export default {
    components: {
        DnCookiebar,
        MenuNavigation,
        FooterMinimal
    },
    mixins: [seo, auth, favicons],
    middleware: ['auth', 'mijnOmgevingEnabled'], // duplicated because nuxt does not merge with mixins
    head() {
        return this.layoutHead;
    },
    computed: {
        ...mapGetters('sites', ['theme']),
        ...mapGetters('sites', {
            isDoen: 'isDoen',
        }),
        layoutHead() {
            return merge(
                {
                    ...this.$nuxtI18nHead(),
                    bodyAttrs: {
                        'data-theme': this.siteTheme
                    }
                },
                this.seoHead,
                this.faviconHead
            );
        }
    }
};
</script>
