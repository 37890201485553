export default {
    self: {
        url: 'localhost:3000'
    },
    newSite: {
        url: 'https://vriendenloterijfonds.doen.natives.dev'
    },
    api: {
        assets: 'http://api.doen.test/',
        endpoint: 'http://api.doen.test/graphql',
        token: 'Bearer -x97ZRnADxIPTzJsDzfYBjK6awVltRz8'
    },
    sites: {
        'vriendenloterijfonds.doen.test:3000': {
            nl: 3
        }
    },
    defaults: {
        siteId: 3,
        locale: 'nl',
        title: 'Stichting DOEN',
        meta: {
            description: 'Stichting DOEN'
        }
    },
    siteConfig: {
        3: {
            title: '<%= title %> | VriendenLoterij Fonds'
        }
    },
    themes: {
        3: 'bglf',
    },
    gtm: {
        3: 'GTM-ZZZZ',
    },
    quickscan: {
        enabled: [3]
    },
    mijnomgeving: {
        enabled: [3]
    },
    projects: {
        enabled: [3]
    },
};
