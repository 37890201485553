'use strict';

import ServerCookie from 'cookie';
import JsCookie from 'js-cookie';

import { isNull, isUndefined } from 'lodash';
import { getBodyObjectFromJwt, isJwtExpired, looksLikeJwt } from '~/utils/jwt';

export default function({ res, req, store, redirect, app: { localePath } }) {
    const jwt = store.getters['mijn/jwt'];

    if (isNull(jwt)) {
        checkIfCookieHasToken({ req, res, store, redirect, localePath });
    } else {
        checkIfCurrentTokenIsValid({ jwt, req, res, store, redirect, localePath });
    }
}

export function getJwtFromCookie({ req }) {
    if (process.server) {
        const cookies = ServerCookie.parse(req.headers.cookie || '');
        return cookies.jwt;
    }

    return JsCookie.get('jwt');
}

/**
 * Check if there is a valid token in a ServerCookie and if so store it
 * in vueX, otherwise force a logout.
 *
 * @param req
 * @param res
 * @param store
 * @param router
 */
function checkIfCookieHasToken({ req, res, store, redirect, localePath }) {
    const jwt = getJwtFromCookie({ req });

    if (!isUndefined(jwt)) {
        // check if the token looks valid.
        if (looksLikeJwt(jwt)) {
            const jwtObject = getBodyObjectFromJwt(jwt);

            if (!isJwtExpired(jwtObject)) {
                // store the token in vueX.
                store.commit('mijn/login', jwt);
                return;
            }
        }
    }

    forceLogout({ req, res, store, redirect, localePath });
}

/**
 * Check if the provided token is a valid JWT token otherwise
 * force a logout.
 * @param jwt
 * @param req
 * @param res
 * @param store
 * @param router
 */
function checkIfCurrentTokenIsValid({ jwt, req, res, store, redirect, localePath }) {
    // check if the token looks valid.
    if (looksLikeJwt(jwt)) {
        const jwtObject = getBodyObjectFromJwt(jwt);

        if (!isJwtExpired(jwtObject)) {
            return;
        }
    }

    forceLogout({ req, res, store, redirect, localePath });
}

/**
 * Force a logout.
 *
 * @param req
 * @param res
 * @param store
 * @param router
 */
function forceLogout({ req, res, store, redirect, localePath }) {
    // run the logout routine to make sure the state is clean.
    store.commit('mijn/logout');
    const locale = store.getters['l10n/locale'];

    if (process.server) {
        res.setHeader('Set-Cookie', ServerCookie.serialize('jwt', null, {
            expires: new Date('1970-01-01 00:00:00'),
            secure: req.secure
        }));
    }

    redirect(localePath({ name: 'mijn-login' }, locale));
}
