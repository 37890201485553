import Vue from 'vue';

import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

export default function({ store }) {
    Vue.filter('localisedDate', (val, dateFormat = 'dd LLLL yyyy') => {
        const newDate = new Date(val);
        if (store.state.i18n.locale === 'nl') {
            return newDate ? format(newDate, dateFormat, { locale: nl }) : '';
        }
        return newDate ? format(newDate, dateFormat) : '';
    });
}
