<template>
    <dn-article class="component">
        <h1 v-if="error && error.statusCode === 404">{{ $t('error.title.404') }}</h1>
        <h1 v-else-if="error && error.title">{{ $t(error.title) }}</h1>
        <h1 v-else>{{ $t('error.title.other') }}</h1>

        <p v-if="error && error.message">{{ $t(error.message) }}</p>

        <p>
            <i18n path="error.body.text">
                <template #home>
                    <a :href="newSite.url">{{ $t('error.body.home') }}</a>
                </template>
                <template #zoek>
                    <a :href="`${newSite.url}/zoek`">{{ $t('error.body.zoek') }}</a>
                </template>
            </i18n>
        </p>
    </dn-article>
</template>

<script>
import Article from '~/patterns/organisms/article/article.vue';

import config from '~/config/config';

export default {
    components: {
        'dn-article': Article
    },
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            newSite: config.newSite
        };
    }
};
</script>
