'use strict';

import { isNull } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { isJwtExpirySoon } from '~/utils/jwt';
export const ONE_HOUR_IN_MILLISECONDS = 3600000;

export default {
    data() {
        return {
            refreshInterval: null
        };
    },
    middleware: ['auth'],
    destroyed() {
        if (this.refreshInterval) {
            window.clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }
    },
    watch: {
        jwt(value) {
            // If the jwt token ever changes to NULL redirect to the login page.
            if (process.client && isNull(value)) {
                this.redirectToLogin();
            }
        }
    },
    computed: {
        ...mapGetters('mijn', ['jwt', 'jwtObject'])
    },
    methods: {
        redirectToLogin() {
            this.$router.push(this.localePath({ name: 'mijn-login' }));
        },
        startTokenRefreshTimer() {
            if (process.client) {
                // Is the token expiring soon? If so refresh directly!.
                if (isJwtExpirySoon(this.jwt)) {
                    this.refreshLogin(this.jwt);
                }

                // Set up an hourly job to refresh the current token to keep it fresh.
                this.refreshInterval = window.setInterval(() => {
                    if (!isNull(this.jwt)) {
                        this.refreshLogin(this.jwt);
                    }
                }, ONE_HOUR_IN_MILLISECONDS);
            }
        },
        ...mapActions('mijn', ['refreshLogin'])
    }
};
