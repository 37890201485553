'use strict';

import { intersection, isEmpty, unionBy } from 'lodash';
import query from '~/graphql';

import getCases from '~/graphql/queries/getCases.graphql';
import getCase from '~/graphql/queries/getCase.graphql';

export const state = () => ({
    all: [],
    relatedLoaded: []
});

export const getters = {
    all: myState => myState.all,
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getByProgramCategoryIds(myState, myGetters, rootState, rootGetters) {
        return ids => myState.all.filter((item) => {
            const itemCategoryIds = item.programCategory.map(v => v.id),
                intersected = intersection(ids, itemCategoryIds);

            return intersected.length > 0 && item.siteId === rootGetters['sites/activeSite'];
        });
    }
};

export const actions = {
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const entry = myGetters.getBySlug(slug)[0];

        if (entry) {
            return entry;
        }

        return dispatch('fetchBySlug', { slug });
    },
    getByProgramCategoryIds({ dispatch, getters: myGetters, state: myState }, { programCategoryIds }) {
        const intersected = intersection(programCategoryIds, myState.relatedLoaded);

        if (intersected.length >= programCategoryIds.length) {
            return myGetters.getByProgramCategoryIds(programCategoryIds);
        }

        return dispatch('fetchByProgramCategoryIds', { programCategoryIds });
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getCase, { siteId: rootGetters['sites/activeSite'], slug }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('appendCases', [entry]);
        }

        return entry;
    },
    async fetchByProgramCategoryIds({ commit, rootGetters }, { programCategoryIds }) {
        const result = await query.call(this, getCases, { siteId: rootGetters['sites/activeSite'], relatedTo: programCategoryIds }),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('appendLoadedCategories', programCategoryIds);
            commit('appendCases', entries);
        }

        return entries;
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getCases, { siteId: rootGetters['sites/activeSite'] }),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('setCases', entries);
        }

        return entries;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    }
};

export const mutations = {
    setCases(myState, cases) {
        myState.all = cases;
    },
    setCase(myState, projectCase) {
        myState.all = [
            ...myState.all,
            projectCase
        ];
    },
    appendCases(myState, cases) {
        myState.all = unionBy(myState.all, cases, v => `${v.id}-${v.siteId}`);
    },
    appendLoadedCategories(myState, categories) {
        myState.relatedLoaded = unionBy(myState.relatedLoaded, categories);
    },
    clearCache(myState) {
        myState.all = [];
        myState.relatedLoaded = [];
    }
};
