'use strict';

// The initial state
export const state = () => ({
    localisations: []
});

// Getters get stuff from the store. This is where you can modify data before sending it to your component.
export const getters = {
    locale: (myState, myGetters, rootState) => rootState.i18n.locale,
    localisations: ({ localisations }) => localisations
};

// Actions don't modify the state directly,
// but instead handle more complex logic and then commit something to the state using mutations
export const actions = {
    setLocalisations({ commit }, localisations) {
        commit('setLocalisations', localisations);
    }
};

// Mutations change the actual data in the state
export const mutations = {
    setLocalisations(myState, localisations) {
        myState.localisations = localisations;
    }
};
