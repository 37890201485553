<template>
    <div>
        <menu-navigation :apply-link="applyLink" />

        <div class="layout">
            <nuxt class="layout__main" />
        </div>

        <dn-footer />

        <client-only>
            <dn-cookiebar />
        </client-only>
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import { merge } from 'lodash';

import favicons from '~/mixins/favicons';

import DnFooter from '~/patterns/organisms/footer/footer';
import DnCookiebar from '~/patterns/molecules/cookie-consent/dn-cookiebar.vue';
import MenuNavigation from '~/patterns/organisms/navigation/navigation';

export default {
    components: {
        DnFooter,
        DnCookiebar,
        MenuNavigation
    },
    mixins: [favicons],
    head() {
        return this.layoutHead;
    },
    computed: {
        applyLink() {
            if (this.isBglf) {
                return '/aanvragen';
            }

            return '/aanvraag-doen';
        },
        ...mapGetters('sites', ['isBglf']),
        ...mapGetters('sites', {
            siteTheme: 'theme'
        }),
        layoutHead() {
            return merge(
                {
                    ...this.$nuxtI18nHead(),
                    bodyAttrs: {
                        'data-theme': this.siteTheme
                    }
                },
                this.faviconHead
            );
        }
    }
};
</script>
