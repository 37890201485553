import { print } from 'graphql/language/printer';
import { get } from 'lodash';
import config from '~/config/config';

/**
 * This helper function will query a Craft CMS backend, automatically including a Preview Token and SiteID.
 *
 * @TODO Refactor this function to suit your needs if you're not using Craft but want some
 * nice defaults in a similar manner
 *
 * @param any query
 *  A query string or a parsed graphql-tag AST
 * @param {*} variables
 */
export default async function executeCraftQuery(query, variables = {}) {
    const headers = {
        Authorization: config.api.token
    };

    // check if the query does not contain siteId then try to fetch it from the store.
    if (!variables.siteId && get(this, '$store.getters.sites/activeSite')) {
        variables.siteId = get(this, '$store.getters.sites/activeSite');
    } else if (!variables.siteId && get(this, 'getters.sites/activeSite')) {
        variables.siteId = get(this, 'getters.sites/activeSite');
    }

    // check if this contains $nuxt content or is a vuex store object then extract preview token if present.
    if (get(this, '$nuxt.context.route.query.x-craft-live-preview') && get(this, '$nuxt.context.route.query.token')) {
        headers['X-Craft-Token'] = get(this, '$nuxt.context.route.query.token');
    } else if (get(this, 'state.route.query.x-craft-live-preview') && get(this, 'state.route.query.token')) {
        headers['X-Craft-Token'] = get(this, 'state.route.query.token');
    }

    const stringifiedQuery = getStringifiedQuery(query);

    // Get fresh data from an API using axios
    let results;
    try {
        results = await this.$axios.post('', {
            query: stringifiedQuery,
            variables
        }, { headers });
    } catch (e) {
        console.error(e); // eslint-disable-line no-console
        if (this.$bugsnag) {
            this.$bugsnag.notify(e, (event) => {
                event.addMetadata('query', {
                    stringifiedQuery,
                    variables,
                    headers
                });
            });
        }

        // do not throw this error again, nuxt gets VERY confused and dies with max call stack exceeded error.
        return;
    }

    return results.data;
}

export function getStringifiedQuery(query) {
    if (typeof query === 'string') {
        return query;
    } else {
        return print(query);
    }
}
