'use strict';

import Vue from 'vue';
import { intersection, isEmpty, trim, unionBy, get, merge } from 'lodash';

import query from '~/graphql';
import getNews from '~/graphql/queries/getNews.graphql';
import getNewsItem from '~/graphql/queries/getNewsItem.graphql';
import getNewsCategories from '~/graphql/queries/getNewsCategories.graphql';
import getNewsTotal from '~/graphql/queries/getNewsTotal.graphql';

export const getUriFromPath = path => trim(path, '/');

const POSTS_PER_PAGE = 12;

/**
 * @todo for now this store is not really multisite compatible, only the categories key.
 *      we should refactor this to store news under the siteId key in the cache. Until
 *      that moment, you can use `changeSite()` to clear the caches and refetch.
 */

export const state = () => ({
    all: [],
    categories: [],
    relatedLoaded: [],
    paginated: { all: [] },
    totalpages: { all: -1 }
});

export const getters = {
    all: myState => myState.all,
    getBySlug(myState, myGetters, rootState, rootGetters) {
        return slug => myState.all.filter((item) => {
            return item.slug === slug && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getByUri(myState, myGetters, rootState, rootGetters) {
        return uri => myState.all.filter((item) => {
            return item.uri === uri && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getById(myState, myGetters, rootState, rootGetters) {
        return id => myState.all.filter((item) => {
            return item.id === `${id}` && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getByNewsCategoryIds(myState, myGetters, rootState, rootGetters) {
        return ids => myState.all.filter((item) => {
            const itemCategoryIds = item.newsCategory.map(category => category.id),
                intersected = intersection(ids, itemCategoryIds);

            return intersected.length > 0 && item.siteId === rootGetters['sites/activeSite'];
        });
    },
    getCategories: (myState, myGetters, rootState, rootGetters) => myState.categories[rootGetters['sites/activeSite']] || [],
    getPaginated: myState => myState.paginated,
    getTotalPages: myState => myState.totalpages
};

export const actions = {
    getById({ dispatch, getters: myGetters }, { id }) {
        const page = myGetters.getById(id)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchById', { id });
    },
    getBySlug({ dispatch, getters: myGetters }, { slug }) {
        const page = myGetters.getBySlug(slug)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchBySlug', { slug });
    },
    getByUri({ dispatch, getters: myGetters, state: myState }, { uri }) {
        const page = myGetters.getByUri(uri)[0];

        if (page) {
            return page;
        }

        return dispatch('fetchByUri', { uri });
    },
    getByNewsCategoryIds({ dispatch, getters: myGetters, state: myState }, { newsCategoryIds }) {
        const intersected = intersection(newsCategoryIds, myState.relatedLoaded);

        if (intersected.length >= newsCategoryIds.length) {
            return myGetters.getByNewsCategoryIds(newsCategoryIds);
        }

        return dispatch('fetchByNewsCategoryIds', { newsCategoryIds });
    },
    getCategories({ dispatch, getters: myGetters }) {
        const categories = myGetters.categories;

        if (!isEmpty(categories)) {
            return categories;
        }

        return dispatch('fetchCategories');
    },
    getByPageId({ dispatch, getters: myGetters, state: myState }, { page, filter = ['all'] }) {
        const key = createFilterKey(filter),
            paginated = get(myState.paginated, `${key}.${page}`, []);

        if (paginated.length > 0) {
            return paginated;
        }

        return dispatch('fetchByPageId', { page, filter });
    },
    getTotalPages({ dispatch, getters: myGetters, state: myState }, { filter = ['all'] }) {
        const key = createFilterKey(filter),
            totalpages = get(myState.totalpages, key, -1);

        if (totalpages > 0) {
            return totalpages;
        }

        return dispatch('fetchTotalPages', { filter });
    },
    async fetchByNewsCategoryIds({ commit, rootGetters }, { newsCategoryIds }) {
        const result = await query.call(this, getNews, { siteId: rootGetters['sites/activeSite'], relatedTo: newsCategoryIds }),
            entries = result.data.entries;

        if (!isEmpty(entries)) {
            commit('appendLoadedCategories', newsCategoryIds);
            commit('appendNews', entries);
        }

        return entries;
    },
    async fetchAll({ commit, rootGetters }) {
        const result = await query.call(this, getNews, { siteId: rootGetters['sites/activeSite'] }),
            entries = result.data.entry;

        if (!isEmpty(entries)) {
            commit('setNews', entries);
        }

        return entries;
    },
    async fetchById({ commit, rootGetters }, { id }) {
        const result = await query.call(this, getNewsItem, { siteId: rootGetters['sites/activeSite'], id }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setNewsItem', entry);
        }

        return entry;
    },
    async fetchBySlug({ commit, rootGetters }, { slug }) {
        const result = await query.call(this, getNewsItem, { siteId: rootGetters['sites/activeSite'], slug }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setNewsItem', entry);
        }

        return entry;
    },
    async fetchByUri({ commit, rootGetters }, { uri }) {
        const result = await query.call(this, getNewsItem, { siteId: rootGetters['sites/activeSite'], uri }),
            entry = result.data.entry;

        if (!isEmpty(entry)) {
            commit('setNewsItem', entry);
        }

        return entry;
    },
    async fetchCategories({ commit, rootGetters }) {
        const result = await query.call(this, getNewsCategories, { siteId: rootGetters['sites/activeSite'] }),
            categories = result.data.categories;

        if (!isEmpty(categories)) {
            commit('setCategories', {
                siteId: rootGetters['sites/activeSite'],
                categories
            });
        }

        return categories;
    },
    async fetchByPageId({ commit, rootGetters }, { page, filter = ['all'] }) {
        const result = await query.call(this, getNews, {
                siteId: rootGetters['sites/activeSite'],
                limit: POSTS_PER_PAGE,
                offset: POSTS_PER_PAGE * page,
                newsCategory: sanitizeFilterForGql(filter)
            }),
            entries = result.data.entries;

        commit('setPaginated', { page, filter, entries });

        return entries;
    },
    async fetchTotalPages({ commit, rootGetters }, { filter = ['all'] }) {
        const result = await query.call(this, getNewsTotal, { siteId: rootGetters['sites/activeSite'], newsCategory: sanitizeFilterForGql(filter) }),
            entries = result.data.entries || [],
            total = entries.length,
            totalpages = Math.ceil(total / POSTS_PER_PAGE);

        commit('setTotalPages', { totalpages, filter });

        return entries;
    },
    clearCache({ commit }) {
        return commit('clearCache');
    },
    changeSite({ commit }) {
        return commit('clearSiteSpecificContent');
    }
};

export const mutations = {
    setNews(myState, news) {
        myState.all = news;
    },
    appendNews(myState, news) {
        myState.all = unionBy(myState.all, news, v => `${v.id}-${v.siteId}`);
    },
    appendLoadedCategories(myState, categories) {
        myState.relatedLoaded = unionBy(myState.relatedLoaded, categories);
    },
    setNewsItem(myState, newsitem) {
        myState.all = [
            ...myState.all,
            newsitem
        ];
    },
    setCategories(myState, { siteId, categories }) {
        Vue.set(myState.categories, siteId, categories);
    },
    setPaginated(myState, { page, entries, filter = ['all'] }) {
        const key = createFilterKey(filter);

        myState.paginated = merge({}, myState.paginated, {
            [key]: {
                [page]: entries
            }
        });
    },
    setTotalPages(myState, { totalpages, filter }) {
        const key = createFilterKey(filter);

        Vue.set(myState.totalpages, key, totalpages);
    },
    clearCache(myState) {
        myState.all = [];
        myState.relatedLoaded = [];
        myState.categories = [];
        myState.paginated = { all: [] };
        myState.totalpages = { all: -1 };
    },
    clearSiteSpecificContent(myState) {
        myState.all = [];
        myState.relatedLoaded = [];
        myState.paginated = { all: [] };
        myState.totalpages = { all: -1 };
    }
};

export function createFilterKey(filter) {
    return Array.isArray(filter) ? filter.join(',') : filter;
}

export function sanitizeFilterForGql(filter) {
    return filter === 'all' || (Array.isArray(filter) && filter[0] === 'all') ? null : filter;
}
