export default {
    self: {
        url: 'https://new.doen.nl'
    },
    newSite: {
        url: 'https://vriendenloterijfonds.doen.nl'
    },
    api: {
        endpoint: 'https://cms.doen.nl/graphql',
        token: 'Bearer QkMpJUM2PBoacsE1M52BhZObgGRapdx1'
    },
    sites: {
        'vriendenloterijfonds.doen.nl': {
            nl: 3
        }
    },
    defaults: {
        siteId: 3,
        locale: 'nl',
        title: 'Stichting DOEN',
        meta: {
            description: 'Stichting DOEN'
        }
    },
    siteConfig: {
        3: {
            title: '<%= title %> | VriendenLoterij Fonds'
        }
    },
    themes: {
        3: 'bglf',
    },
    gtm: {
        3: 'GTM-TRFG39P',
    },
    quickscan: {
        enabled: [3]
    },
    mijnomgeving: {
        enabled: [3]
    },
    projects: {
        enabled: [3]
    },
};
