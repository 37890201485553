export default [
    {
        path: '/styleguide',
        label: 'Main',
        component: 'styleguide/pages/base/index.vue'
    },
    {
        path: '/styleguide/typography',
        label: 'Typography',
        component: 'styleguide/pages/typography/index.vue'
    },
    {
        path: '/styleguide/form',
        label: 'Form',
        component: 'styleguide/pages/form/index.vue'
    },
    {
        path: '/styleguide/media',
        label: 'Media',
        component: 'styleguide/pages/media/index.vue'
    },
    {
        path: '/styleguide/components',
        label: 'Components',
        component: 'styleguide/pages/components/index.vue'
    }
];
